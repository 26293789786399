import { createAction, props } from '@ngrx/store';
import { Page } from '../../shared/models/page/page.model';
import { User } from '../../shared/models/user/user.model';

const PREFIX = '[User]';

export interface SetProductOwnerPermissionPayload {
  entity: string;
  record: string | number;
}

export const login = createAction(`${PREFIX} Login`);
export const loginSuccess = createAction(`${PREFIX} Login Success`, props<Page<User>>());
export const loginError = createAction(`${PREFIX} Login Error`);
export const setInstanaData = createAction(`${PREFIX} Set Instana Data`);
export const reset = createAction(`${PREFIX} Reset`);
export const invalidUserTypeLoginAttempt = createAction(`${PREFIX} Invalid User Type Login Attempt`);

export const setProductOwnerPermission = createAction(
  `${PREFIX} Set Product Owner Permission`,
  props<SetProductOwnerPermissionPayload>()
);
export const removeProductOwnerPermission = createAction(
  `${PREFIX} Remove Product Owner Permission`,
  props<SetProductOwnerPermissionPayload>()
);
